import axios from '../../plugins/axios'
import { apiUrl, getAuth } from './utils'
import * as type from '../reducers/types'

export const fetchSectionms = () => async dispatch => {
  const url = `${apiUrl}/sectionm-list`

  return axios.get(url, getAuth()).then(({ data }) => {
    dispatch({
      type: type.GET_SECTIONM_LIST,
      sectionms: data
    })
  })
}
