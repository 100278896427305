import styled from 'styled-components'

export const WatchmanInfo = styled.div`
  .avatar {
    margin: 20px auto;
  }
  .info {
    margin-top: 10px;
    .actions {
      display: flex;
      justify-content: space-around;
    }
  }
`
