import Croppr from 'croppr'

const setSize = 200

export const loadHead = () => {
  // Verificar si existe el css antes de agregarlo
  const existingCss = document.getElementById('cropprCss')

  if (!existingCss) {
    /* Agregando CDN css para recortar las imagenes */
    const css = document.createElement('link')
    css.href =
      'https://cdn.jsdelivr.net/gh/jamesssooi/Croppr.js@2.3.0/dist/croppr.min.css'
    css.rel = 'stylesheet'
    css.id = 'cropprCss'
    document.head.appendChild(css)
  }
}

const isCorrectDimensions = urlImage => {
  // Dimensiones de la imagen para validar
  return new Promise(resolve => {
    console.log('isCorrectDimensions', urlImage)
    const img = new Image()
    img.onload = function () {
      resolve({
        width: this.width.toFixed(0),
        height: this.height.toFixed(0)
      })
    }

    img.src = urlImage
  })
}

const cutImage = (data, urlImage, setImageBase64) => {
  // Variables
  const initX = data.x,
    initY = data.y,
    initWidth = data.width,
    initHeight = data.height,
    newWidth = setSize,
    zoom = initWidth / newWidth

  // La declaro
  let imageTemp = new Image()
  // Cuando la imagen se carge se procederá al recorte
  imageTemp.onload = () => {
    /* Se crea un canvas para cargar el recorte de la libreria */
    let canvasVersion = document.createElement('canvas')
    canvasVersion.setAttribute('width', `${setSize}px`)
    canvasVersion.setAttribute('height', `${setSize}px`)

    /* Se dibuja en el canvas la imagen recortada */
    let ctx = canvasVersion.getContext('2d')
    ctx.drawImage(
      imageTemp,
      initX,
      initY,
      initWidth * zoom,
      initHeight * zoom,
      0,
      0,
      initWidth,
      initHeight
    )

    // Se transforma a base64 para almacenar
    setImageBase64(canvasVersion.toDataURL('image/jpeg'))
  }

  // Proporciona la imagen cruda, sin editarla por ahora
  imageTemp.src = urlImage
}

/* urlImage es la imagen elegida por el usaurio. setImageBase64 setea para enviar la imgane edita */
export const openEdit = async (urlImage, setImageBase64) => {
  const imgDimensions = await isCorrectDimensions(urlImage),
    size = setSize * 2

  if (imgDimensions.width >= size && imgDimensions.height >= size) {
    const editLayer = document.querySelector('#editor')
    // Borra editor en caso que existiera una imagen previa
    editLayer.innerHTML = ''

    const cropprImg = document.createElement('img')
    cropprImg.setAttribute('id', 'croppr')
    editLayer.appendChild(cropprImg)

    // Envia la imagen al editor para su recorte
    document.querySelector('#croppr').setAttribute('src', urlImage)

    // Crea el editor
    new Croppr('#croppr', {
      aspectRatio: 1,
      minSize: [setSize / 2, setSize / 2, 'px'],
      onCropEnd: e => {
        cutImage(e, urlImage, setImageBase64)
      }
    })
  } else {
    alert(
      `La imagen seleccionada es muy pequeña. Debe ser de al menos ${size}px de alto y de ancho`
    )
    console.log('La imagen seleccionada es muy pequeña.')
  }
}
