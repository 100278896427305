import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin: 1rem auto;
  width: 80%;
`

export const SettingsContainer = styled.div`
  margin-top: 1rem;
`
