import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as type from '../../store/reducers/types';
import pushNotifications from '../../plugins/notifications';

export const EndSession = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector( ( { login } ) => login );

  /* Eliminando la conexion con el servidor push */
  pushNotifications.unsubscription( session );

  localStorage.clear();
  history.push( '/inicio' );

  dispatch( {
    type: type.LOGIN_FORM,
    form: {}
  } );

  setTimeout( () => {
    window.location.reload();
  }, 500 );

  return <div></div>;
};
