import axios from 'axios';

const baseURL = process.env.REACT_APP_API;
const nameStore = process.env.REACT_APP_TOKEN_STORE;

/* Debe ser una funcion para que en cada ciclo evalue y traiga resultado del token guardado */
const token = () => localStorage.getItem(nameStore);

const http = axios.create({
	baseURL,
	withCredentials: false,
	headers: {
		Authorization: token() ? `Bearer ${token()}` : '',
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

// Interceptor de solicitud
http.interceptors.request.use(
	config => {
		// La logica que desee realizar
		return config;
	},
	error => {
		// La logica que desee realizar
		return Promise.reject(error);
	}
);

// Interceptor de respuesta
http.interceptors.response.use(
	response => {
		// Encaso de un token invaido
		if (
			response.data.errors &&
			response.data.errors[0].message === 'Invalid token.'
		) {
			localStorage.clear();
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}

		return response;
	},
	error => {
		// Encaso de un token invaido
		if (
			error.response &&
			error.response.data &&
			error.response.data.message === 'Invalid token.'
		) {
			localStorage.clear();
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
		return Promise.reject(error);
	}
);

export default http;
