/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'
import UserWrapper from '../../hoc/userWrapper'
import Tabs, { Panel } from '../../UI/tabs'
import Button from '../../UI/button'
import List from '../../UI/list'
import { DebtWrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getDebts, getPayedDebts } from '../../../store/actions/debts'

const mapFees = arr =>
  arr.map(item => ({
    id: item.id,
    title: `Periodo: ${item.year} Cuota ${item.fees_type}`,
    subtitle: `Vencimiento: ${moment(item.expiration).format('DD/MM/YYYY')}`
  }))

const Debts = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const debts = useSelector(({ debts }) => debts.debtsList)
  const payed = useSelector(({ debts }) => debts.payedDebts)
  const codeActive = useSelector(({ codeActive }) => codeActive)
  const [debtsToPay, setToPay] = useState([])
  const [payedDebts, setPayed] = useState([])

  const handleItem = ({ id }) => {
    const debtDetail = debts.reduce(
      (acc, curr) => (curr.id === id ? curr : acc),
      {}
    )
    history.push({
      pathname: `/deudas/${id}`,
      state: { type: 'notpayed', data: debtDetail }
    })
  }
  const handlePayed = ({ id }) => {
    const debtDetail = payed.reduce(
      (acc, curr) => (curr.id === id ? curr : acc),
      {}
    )

    history.push({
      pathname: `/deudas/${id}`,
      state: { type: 'payed', data: debtDetail }
    })
  }

  useEffect(() => {
    codeActive && dispatch(getDebts(codeActive))
    codeActive && dispatch(getPayedDebts(codeActive))
  }, [codeActive])

  useEffect(() => {
    if (debts && debts.length >= 1) {
      setToPay(mapFees(debts))
    }
  }, [debts])

  useEffect(() => {
    if (payed && payed.length >= 1) {
      setPayed(mapFees(payed))
    }
  }, [payed])

  return (
    <UserWrapper pathName='Deudas'>
      <DebtWrapper>
        <Tabs selected={0}>
          <Panel title='por pagar'>
            <List items={debtsToPay} action={handleItem} />
          </Panel>
          <Panel title='pagado'>
            <List items={payedDebts} action={handlePayed} />
          </Panel>
        </Tabs>
        <Button
          width='80%'
          display='block'
          onClick={() => history.push({ pathname: '/reporte' })}
        >
          Enviar Reporte de Pago
        </Button>
      </DebtWrapper>
    </UserWrapper>
  )
}

export default Debts
