import axios from '../../plugins/axios'
import * as type from '../reducers/types'
import { apiUrl, getAuth } from './utils'

export const editProfile = (form, code) => async dispatch => {
  const url = code
    ? `${apiUrl}/update-irrigator-profiles/${code}`
    : `${apiUrl}/update-irrigator-profiles`

  return axios
    .put(url, form, getAuth())
    .then(({ data }) => {
      dispatch({
        type: type.EDIT_PROFILE,
        profile: data
      })
      dispatch({
        type: type.NOTIFICATIONS,
        notification: { message: 'Guardado con éxito.' }
      })
    })
    .catch(err => dispatch({ type: type.ERROR, error: err }))
}

export const editProfileAcmUser = form => async dispatch => {
  const url = `${apiUrl}/update-acmuser-profiles`

  return axios
    .put(url, form, getAuth())
    .then(({ data }) => {
      dispatch({
        type: type.EDIT_PROFILE,
        profile: data
      })
      dispatch({
        type: type.NOTIFICATIONS,
        notification: { message: 'Guardado con éxito.' }
      })
    })
    .catch(err => dispatch({ type: type.ERROR, error: err }))
}
