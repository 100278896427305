import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Button from '../UI/button'
import Modal from '../UI/modal'
import FormImput from '../UI/input'
import { Wrapper, Slide, FormWrapp } from './styles'
import slide1 from '../../assets/slide1.png'
import slide1ogg from '../../assets/slide1.ogg'
import slide1mp3 from '../../assets/slide1.mp3'
import slide2 from '../../assets/slide2.png'
import slide2ogg from '../../assets/slide2.ogg'
import slide2mp3 from '../../assets/slide2.mp3'
import slide3 from '../../assets/slide3.png'
import slide3ogg from '../../assets/slide3.ogg'
import slide3mp3 from '../../assets/slide3.mp3'
import slide4 from '../../assets/slide4.png'
import slide4ogg from '../../assets/slide4.ogg'
import slide4mp3 from '../../assets/slide4.mp3'

import {
  editProfile,
  editProfileAcmUser
} from '../../store/actions/editProfile'
import { useDispatch, useSelector } from 'react-redux'
import { ModalContent } from '../hoc/userWrapper'
import ReactGA from 'react-ga'

const stopAllAudio = () => {
  ;[0, 1, 2, 3].forEach(id => {
    const audio = document.getElementById(`desc-${id}`)
    audio.pause()
    audio.currentTime = 0
  })
}

const playAudio = id => {
  stopAllAudio()
  const audio = document.getElementById(`desc-${id}`)
  audio.play()
}

export const OnBoarding = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user)
  const [volume, setVolume] = useState(false)
  const [modalOn, setModal] = useState(false)
  const [form, setForm] = useState({})
  const [error, setError] = useState()
  const [currentSlider, setCurrentSlider] = useState(0)
  const location = props.location.pathname

  ReactGA.pageview(location)

  const settings = {
    dots: true,
    infinite: false
  }

  const handleForm = async () => {
    /* Solo para regantes */
    const code = user.code
    if (user.profile) {
      await dispatch(
        editProfile(
          { ...form, code, app_setting: { size: '13px', theme: 0 } },
          code
        )
      )
      history.push('/panel-de-control')
    }
  }

  const handleUser = async () => {
    /* Es regante y es nuevo */
    if (user.profile && !user.profile.app_setting.size) {
      setModal(true)
    } else {
      /* Es usuario de acm y es nuevo */
      if (user.firts_time)
        await dispatch(
          editProfileAcmUser({
            firts_time: false,
            app_setting: { size: '13px', theme: 0 }
          })
        )

      history.push('/panel-de-control')
    }
  }

  const activeAudio = () => {
    setVolume(!volume)
    /* Si se enciende reproduce el audio */
    if (!volume) {
      playAudio(currentSlider)
    } else {
      stopAllAudio()
    }
  }

  const changeAudio = slideIndex => {
    setCurrentSlider(slideIndex)
    /* Si está encendido reproduce el audio */
    if (volume) {
      playAudio(slideIndex)
    }
  }

  return (
    <Wrapper>
      <Button
        className='toggle-volume'
        background='none'
        onClick={() => activeAudio()}
      >
        <i className={`fas ${volume ? 'fa-volume-mute' : 'fa-volume-up'}`} />
      </Button>
      <Slider {...settings} afterChange={slideIndex => changeAudio(slideIndex)}>
        <Slide bgImg={slide1}>
          <p>
            Bienvenido a la App de la Asociación Canal Maule.
            <audio id='desc-0'>
              <source src={slide1ogg} type='audio/ogg' />
              <source src={slide1mp3} type='audio/mpeg' />
            </audio>
          </p>
        </Slide>
        <Slide bgImg={slide2}>
          <p>
            Comunícate con tu celador de forma rápida y directa
            <audio id='desc-1'>
              <source src={slide2ogg} type='audio/ogg' />
              <source src={slide2mp3} type='audio/mpeg' />
            </audio>
          </p>
        </Slide>
        <Slide bgImg={slide3}>
          <p>
            Revisa el volumen de la Laguna del Maule y tus cuotas de agua.
            <audio id='desc-2'>
              <source src={slide3ogg} type='audio/ogg' />
              <source src={slide3mp3} type='audio/mpeg' />
            </audio>
          </p>
        </Slide>
        <Slide bgImg={slide4}>
          <p>
            Consulta el estado de tus solicitudes o solicita una visita.
            <Button
              display='block'
              margin='0 auto'
              background='white'
              color='primary'
              onClick={() => handleUser()}
            >
              Comenzar
            </Button>
            <audio id='desc-3'>
              <source src={slide4ogg} type='audio/ogg' />
              <source src={slide4mp3} type='audio/mpeg' />
            </audio>
          </p>
        </Slide>
      </Slider>
      {error && error.length >= 4 && (
        <Modal>
          <ModalContent type='error'>
            <i className='fas fa-exclamation-triangle'></i>
            <p>{error}</p>
            <Button
              background='error'
              width='100%'
              onClick={() => setError(false)}
            >
              Volver
            </Button>
          </ModalContent>
        </Modal>
      )}
      {modalOn && (
        <Modal
          closeAction={setModal}
          action={() => handleForm()}
          txtAction='Guardar'
        >
          <h1>Actualiza tus datos</h1>
          <p>
            Antes de comenzar, necesitamos los siguientes datos para mejorar tu
            experiencia en la App Canal Maule
          </p>
          <FormWrapp onSubmit={() => handleForm(form)}>
            <FormImput
              className='form-input'
              label='Número de teléfono celular'
            >
              <input
                type='phone'
                placeholder='+569 XXXX XXXX'
                onChange={e =>
                  setForm({ ...form, contact_telephone: e.target.value })
                }
              />
            </FormImput>
            <FormImput
              className='form-input'
              label='Dirección para correspondencia'
            >
              <input
                type='text'
                placeholder='Calle Nº XX, Comuna'
                onChange={e =>
                  setForm({ ...form, contact_address: e.target.value })
                }
              />
            </FormImput>
            <FormImput className='form-input' label='Correo electrónico'>
              <input
                type='mail'
                placeholder='ejemplo@correo.cl'
                onChange={e => setForm({ ...form, email: e.target.value })}
              />
            </FormImput>
          </FormWrapp>
        </Modal>
      )}
    </Wrapper>
  )
}
