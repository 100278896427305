import React from 'react'
import { UserPicture } from './styles'
import image from '../../../assets/placeholder-profile.jpg'

const Avatar = props => {
  const { image, altTxt, onClick, onChange, className, width, height } = props
  return (
    <UserPicture className={className} width={width} height={height}>
      <img src={image} onClick={onClick} onChange={onChange} alt={altTxt} />
    </UserPicture>
  )
}

Avatar.defaultProps = {
  image,
  altTxt: 'foto de perfil',
  width: '65px',
  height: '65px'
}

export default Avatar
