export const options = {
  // mode: 'cors',
  // cache: 'default',
  'Content-Type': 'application/json'
}

export const apiUrl = process.env.REACT_APP_API

export const getAuth = () => {
  const token = () => localStorage.getItem('session')

  const auth = {
    headers: {
      ...options,
      Authorization: `Bearer ${token()}`
    }
  }

  return auth
}
