import * as types from './types'

export const sectionms = (sectionms = {}, action) => {
  switch (action.type) {
    case types.GET_SECTIONM_LIST:
      return action.sectionms
    default:
      return sectionms
  }
}
