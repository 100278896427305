import axios from 'axios'
const PUBLIC_VAPID_KEY = 'BAEr34BQevleaFYcs2G2EnRP9IMx9r-KhDv21_C2VAmsSXjojCn78SrMi60jtc-ECyWJqOF0YKk0iXc7Rd3OAio';

const urlBase64ToUint8Array = ( base64String ) => {
  const padding = "=".repeat( ( 4 - ( base64String.length % 4 ) ) % 4 );
  const base64 = ( base64String + padding ).replace( /-/g, "+" ).replace( /_/g, "/" );

  const rawData = window.atob( base64 );
  const outputArray = new Uint8Array( rawData.length );

  for ( let i = 0; i < rawData.length; ++i ) {
    outputArray[ i ] = rawData.charCodeAt( i );
  }
  return outputArray;
};

const subscription = async (user) => {
  // Usando Service Worker previamente registrado
  const register = await navigator.serviceWorker.ready

  // Listen Push Notifications
  const subscription = await register.pushManager.subscribe( {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array( PUBLIC_VAPID_KEY )
  } );

  /* Definiendo de quien es la suscripcion */
  if ( user.code || user.association_user?.id ) {
    let endpoint;

    if(user.code) endpoint=`${process.env.REACT_APP_PUSH}/subscription?irrigator_code=${ user.code }`
    if(user.association_user?.id) endpoint=`${process.env.REACT_APP_PUSH}/subscription?association_users=${ user.association_user.id }`

    return await axios.post( endpoint, JSON.stringify( subscription ),{
      headers: {
        "Content-Type": "application/json"
      }
    } );
  }
};

const unsubscription = async (user) => {
  /* Definiendo de quien es la suscripcion */
  if ( user.code || user.association_user?.id ) {
    let endpoint;

    if(user.code) endpoint=`${process.env.REACT_APP_PUSH}/subscription?irrigator_code=${ user.code }`
    if(user.association_user?.id) endpoint=`${process.env.REACT_APP_PUSH}/subscription?association_users=${ user.association_user.id }`

    return await axios.post( endpoint, "",{
      headers: {
        "Content-Type": "application/json"
      }
    } );
  }
};

const notifications = {
  subscription,
  unsubscription
};

export default notifications;