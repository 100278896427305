import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChannels } from '../../../store/actions/dashboard'
import { setNotification } from '../../../store/actions/notifications'
import { fetchWatchmans } from '../../../store/actions/watchmans'
import { fetchSectionms } from '../../../store/actions/sectionms'
import UserWrapper from '../../hoc/userWrapper'
import Button from '../../UI/button'
import Card from '../../UI/card'
import { FormIrrigator, FormWatchman, FormSectionm } from './form'
import { Wrapper } from './styles'

const NotificationsForm = () => {
  // store
  const dispatch = useDispatch()
  const channelsList = useSelector(({ channelsList }) => channelsList)
  const watchmansList = useSelector(({ watchmans }) => watchmans)
  const sectionmsList = useSelector(({ sectionms }) => sectionms)

  // states
  const [area, setArea] = useState('irrigators')

  const submitForm = notification => {
    let toSend = { ...notification }

    /* Ajuste para enviar el archivo adjunto */
    if (toSend.file) {
      delete toSend.file
      let formdata = new FormData()
      formdata.append(
        'files.attached',
        notification.file,
        notification.file.name
      )
      formdata.append('data', JSON.stringify(toSend))
      toSend = formdata
    }

    dispatch(setNotification(toSend))
  }

  useEffect(() => {
    dispatch(fetchWatchmans())
    dispatch(fetchSectionms())
    dispatch(getChannels())
  }, [dispatch])

  return (
    <UserWrapper>
      <Wrapper>
        <h1>Crear notificación</h1>
        <p>Selecciona un area a notificar</p>
        <div className='selector'>
          <Button
            background={area === 'irrigators' ? 'primary' : 'grey'}
            onClick={() => setArea('irrigators')}
          >
            Regantes
          </Button>
          <Button
            background={area === 'watchmans' ? 'primary' : 'grey'}
            onClick={() => setArea('watchmans')}
          >
            Celadores
          </Button>
          <Button
            background={area === 'sectionms' ? 'primary' : 'grey'}
            onClick={() => setArea('sectionms')}
          >
            Jefes S
          </Button>
        </div>
        <Card className='form-card'>
          {area === 'irrigators' ? (
            <FormIrrigator
              watchmans={watchmansList}
              channels={channelsList}
              submitAction={submitForm}
            />
          ) : area === 'watchmans' ? (
            <FormWatchman
              watchmans={watchmansList}
              channels={channelsList}
              submitAction={submitForm}
            />
          ) : (
            <FormSectionm sectionms={sectionmsList} submitAction={submitForm} />
          )}
        </Card>
      </Wrapper>
    </UserWrapper>
  )
}

export default NotificationsForm
