import styled from 'styled-components'
import moment from 'moment'

const RecorderContainer = styled.div`
  padding: 20px 0;
  #recorder-audio-control {
    display: block;
    margin: 15px 0 0 0;
    > div {
      display: inline-block;
      border-radius: 100px;
      width: 70px;
      height: 70px;
      background: #3a83d5;
      margin: 0 5px;

      i {
        background: unset;
        font-size: 30px;
        margin-top: 7px;
      }
    }
  }

  #recorder-start-audio,
  #recorder-stop-audio,
  #recorder-play-audio,
  #recorder-restart-audio {
    display: block;
    border-radius: 100px;
    width: 150px;
    height: 150px;
    margin: auto;

    i {
      background: unset;
      font-size: 70px;
      margin-top: 20px;
    }
  }

  #recorder-start-audio,
  #recorder-play-audio,
  #recorder-restart-audio {
    background: #185aa5;
  }

  #recorder-stop-audio {
    background: #dc3e3e;
    i {
      font-size: 70px;
      margin-top: 25px;
    }
  }

  #recorder-counter {
    font-size: 35px;
  }
`

const showHide = (elements = [], propName) => {
  elements.forEach(element => {
    document.getElementById(element).style.display = propName
  })
}

/* Iniciar grabacion */
const recordStart = mediaRecorder => {
  mediaRecorder.start()
  showHide(['recorder-start-audio'], 'none')
  showHide(['recorder-stop-audio'], 'block')
}

/* Detener grabacion */
const recordStop = mediaRecorder => {
  mediaRecorder.stop()
  showHide(['recorder-stop-audio'], 'none')
  showHide(['recorder-repeat-audio', 'recorder-send-audio'], 'inline-block')
}

/* Borrar grabacion y volver a intentar */
const recordDelete = audio => {
  const element = audio.parentNode

  while (element.firstChild) {
    element.removeChild(element.firstChild)
  }

  showHide(['recorder-start-audio'], 'block')
  showHide(
    [
      'recorder-repeat-audio',
      'recorder-send-audio',
      'recorder-play-audio',
      'recorder-restart-audio'
    ],
    'none'
  )
}

const time_convert = num => {
  var m = Math.floor((num % 3600) / 60)
  var s = Math.floor((num % 3600) % 60)
  const time =
    m.toString().padStart(2, '0') + ':' + s.toString().padStart(2, '0')
  return time
}

const activeRecorder = options => {
  if (navigator.mediaDevices) {
    const constraints = { audio: true }
    let chunks = []
    let counterListener = null
    let totalAudio = 0
    let totalAudioListener = null
    const fnSetFile = options.sendAudio

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        const counterTag = document.getElementById('recorder-counter')
        const mediaRecorder = new MediaRecorder(stream)

        const startButton = document.getElementById('recorder-start-audio')
        startButton.onclick = () => {
          totalAudioListener = setInterval(() => {
            totalAudio++
            counterTag.innerHTML = `${time_convert(totalAudio)}`
          }, 1000)
          recordStart(mediaRecorder)
        }

        const stopButton = document.getElementById('recorder-stop-audio')
        stopButton.onclick = () => {
          clearInterval(totalAudioListener)
          recordStop(mediaRecorder)
        }

        mediaRecorder.onstop = e => {
          counterTag.innerHTML = `00:00 / ${time_convert(totalAudio)}`

          const clipContainer = document.getElementById('recorder-audio')
          const audio = document.createElement('audio')
          const deleteButton = document.getElementById('recorder-repeat-audio')
          const sendButton = document.getElementById('recorder-send-audio')

          //clipContainer.classList.add('clip')
          audio.setAttribute('controls', '')
          audio.setAttribute('style', 'display:none;')
          audio.setAttribute('id', 'recorder-msg')
          audio.setAttribute('src', null)

          clipContainer.appendChild(audio)

          audio.controls = true
          const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
          var audioURL = URL.createObjectURL(blob)
          audio.src = audioURL

          showHide(['recorder-play-audio'], 'block')
          const playButton = document.getElementById('recorder-play-audio')
          playButton.onclick = () => {
            audio.play()
            showHide(['recorder-play-audio'], 'none')
            showHide(['recorder-restart-audio'], 'block')

            counterListener = setInterval(() => {
              counterTag.innerHTML = `${time_convert(
                Math.round(audio.currentTime)
              )} / ${time_convert(totalAudio)}`
            }, 1000)
          }

          const restartButton = document.getElementById(
            'recorder-restart-audio'
          )
          restartButton.onclick = () => {
            audio.pause()
            audio.currentTime = 0
            clearInterval(counterListener)
            counterTag.innerHTML = `00:00 / ${time_convert(totalAudio)}`
            showHide(['recorder-play-audio'], 'block')
            showHide(['recorder-restart-audio'], 'none')
          }

          /* Cuando finaliza la reproduccion del audio */
          audio.addEventListener('ended', () => {
            clearInterval(counterListener)
            counterTag.innerHTML = `00:00 / ${time_convert(totalAudio)}`
            showHide(['recorder-play-audio'], 'block')
            showHide(['recorder-restart-audio'], 'none')
          })

          sendButton.onclick = async () => {
            clearInterval(counterListener)
            counterTag.innerHTML = '00:00'
            audio.pause()
            audio.currentTime = 0
            totalAudio = 0

            const buffer = await blob.arrayBuffer()

            const nameFile = `audio-${moment().format(
              'YYYY-MM-DD HH:mm:ss'
            )}.ogg`

            const file = new File([buffer], nameFile)
            fnSetFile(file)
          }

          chunks = []

          deleteButton.onclick = () => {
            clearInterval(counterListener)
            counterTag.innerHTML = '00:00'
            totalAudio = 0
            recordDelete(audio)
          }
        }

        mediaRecorder.ondataavailable = e => {
          chunks.push(e.data)
        }
      })
      .catch(err => {
        console.log('The following error occurred: ' + err)
      })
  }
}

const RecorderAudio = props => {
  const { sendAudio, closeModal } = props

  return (
    <RecorderContainer
      onLoad={activeRecorder({
        sendAudio: sendAudio
      })}
    >
      <div id='recorder-audio'></div>

      <div id='recorder-start-audio'>
        <i className='fas fa-microphone'></i>
      </div>

      <div id='recorder-stop-audio' style={{ display: 'none' }}>
        <i className='fas fa-stop'></i>
      </div>

      <div id='recorder-play-audio' style={{ display: 'none' }}>
        <i className='fas fa-volume-up'></i>
      </div>

      <div id='recorder-restart-audio' style={{ display: 'none' }}>
        <i className='fas fa-volume-mute'></i>
      </div>

      <div id='recorder-counter'>00:00</div>

      <div id='recorder-audio-control'>
        <div id='recorder-repeat-audio' style={{ display: 'none' }}>
          <i className='fas fa-undo'></i>
        </div>

        <div id='recorder-send-audio' style={{ display: 'none' }}>
          <i className='fab fa-telegram-plane'></i>
        </div>

        <div id='recorder-close' onClick={() => closeModal()}>
          <i className='fas fa-sign-out-alt'></i>
        </div>
      </div>
    </RecorderContainer>
  )
}

export default RecorderAudio
