export const LOADING = 'LOADING'
export const ERROR = 'ERROR'
export const FORMDATA = 'FORMDATA'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const SET_ISSUES = 'SET_ISSUES'
export const GET_BANKS = 'GET_BANKS'

// LOGIN
export const LOGIN_FORM = 'LOGIN_FORM'
export const EDIT_INFO_FORM = 'EDIT_INFO_FORM'
export const GET_USER = 'GET_USER'

// EDIT PROFILE
export const FETCH_PROFILE = 'FETCH_PROFILE'
export const PROFILE_FORM = 'PROFILE_FORM'
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const EDIT_SETTINGS = 'EDIT_SETTINGS'
export const SET_CODE = 'SET_CODE'

// DASHBOARD
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const GET_CHANNELS = 'GET_CHANNELS'

// INFORMATION SECTION
export const GET_INFOCARDS = 'GET_INFOCARDS'

// REQUESTS
export const REFRESH_REQUESTS_OPEN_CLOSED = 'REFRESH_REQUESTS_OPEN_CLOSED'
export const FETCH_REQUESTS = 'FETCH_REQUESTS'
export const FETCH_CLOSED_REQUESTS = 'FETCH_CLOSED_REQUESTS'
export const GET_REQUEST_DETAILS = 'GET_REQUEST_DETAILS'
export const REQUEST_FORM = 'REQUEST_FORM'
export const POST_REQUEST = 'POST_REQUEST'
export const GET_ROLES = 'GET_ROLES'
export const RESET_CHAT = 'RESET_CHAT'
export const LOADING_MESSAGE = 'LOADING_MESSAGE'

// DEBTS
export const GET_DEBTS = 'GET_DEBTS'
export const GET_PAYED_DEBTS = 'GET_PAYED_DEBTS'

// VISITS
export const GET_VISITS = 'GET_VISITS'
export const GET_VISITS_DETAILS = 'GET_VISITS_DETAILS'
export const REQUEST_VISIT = 'REQUEST_VISIT'
export const GET_VISIT_BIO = 'GET_VISIT_BIO'
export const LOADING_REPORTS = 'LOADING_REPORTS'

// REPORTS & BINNALE'S
export const GET_REPORTS = 'GET_REPORTS'
export const GET_BINNACLES = 'GET_BINNACLES'
export const GET_VISIT_REPORTS = 'GET_VISIT_REPORTS'
export const GET_CHANNEL_REPORTS = 'GET_CHANNEL_REPORTS'
export const LOADING_BINNACLES = 'LOADING_BINNACLES'
export const COUNT_REPORTS = 'COUNT_REPORTS'
export const COUNT_BINNACLES = 'COUNT_BINNACLES'

// IRRIGATOR DETAIL
export const GET_IRRIGATORS_LIST = 'GET_IRRIGATORS_LIST'
export const FILTER_IRRIGATORS_LIST = 'FILTER_IRRIGATORS_LIST'
export const GET_IRRIGATOR = 'GET_IRRIGATOR'
export const GET_HISTORY = 'GET_HISTORY'

// WATCHMAN LIST
export const GET_WATCHMEN_LIST = 'GET_WATCHMEN_LIST'

// SECTIONM LIST
export const GET_SECTIONM_LIST = 'GET_SECTIONM_LIST'
