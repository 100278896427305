import React, { useState, useEffect } from 'react'
import UserWrapper from '../../hoc/userWrapper'
import Card from '../../UI/card'
import Button from '../../UI/button'
import Modal from '../../UI/modal'
import { DeatilWrapper, Detail, Row, ModalContent, Actions } from './styles'
import { useHistory } from 'react-router'
import FormInput from '../../UI/input'
import { useDispatch, useSelector } from 'react-redux'
import {
  getHistory,
  getIrrigatorDetails,
  updateCoords
} from '../../../store/actions/irrigator'
import { GhostLine } from '../../UI/ghostLoader'
import axios from '../../../plugins/axios'
import { apiUrl, getAuth } from '../../../store/actions/utils'
import * as types from '../../../store/reducers/types'
import moment from 'moment'
import Tabs, { Panel } from '../../UI/tabs'
import List from '../../UI/list'

const annotation = ann =>
  ann.map(item => ({
    id: item.id,
    title: item.subject,
    code: item.irrigator.code,
    subtitle: item.irrigator.name
  }))

const IrrigatorDetail = props => {
  const { location } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const irrigator = useSelector(({ irrigator }) => irrigator)
  const loading = useSelector(({ loading }) => loading)

  const [form, setForm] = useState({
    date: moment().format('YYYY-MM-DDTHH:mm'),
    message: ''
  })
  const [modal, showModal] = useState(false)
  const [irrigatorDetails, setData] = useState(irrigator.detail)
  const [geoLocation, setGeoLocation] = useState()
  const userLogged = JSON.parse(localStorage.getItem('userActive'))

  const handleModal = () => {
    const newForm = {
      ...form,
      message:
        modal === 'urgent'
          ? `El día ${form.date}: ${form.message}`
          : form.message,
      irrigators: [irrigatorDetails.code],
      type: modal,
      activation: moment().utc().format('YYYY-MM-DD HH:mm')
    }

    axios
      .post(`${apiUrl}/notification-centers`, newForm, getAuth())
      .then(() => showModal(false))
      .catch(err => {
        showModal(false)
        dispatch({ type: types.ERROR, error: err })
      })
  }

  const handleItem = ({ id, code }) => {
    const data = { id: id, code: code }
    history.push({ pathname: `/anotaciones/${id}`, state: data })
  }

  // handler for the location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        const ubication = {
          coordinates: {
            accuracy: pos.coords.accuracy,
            altitude: pos.coords.altitude,
            altitudeAccuracy: pos.coords.altitudeAccuracy,
            heading: pos.coords.heading,
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
            speed: pos.coords.speed
          }
        }

        if (irrigatorDetails.profile)
          dispatch(updateCoords(irrigatorDetails.profile.id, ubication))
        setGeoLocation(ubication)
      })
    } else {
      alert('Este navegador no admite la geolocalización.')
    }
  }

  useEffect(() => {
    const code = location.state.data.code
    dispatch(getIrrigatorDetails(code))
  }, [dispatch, location])

  useEffect(() => {
    if (irrigator.detail) {
      setData(irrigator.detail)
      dispatch(getHistory(irrigator.detail.code))
    }
  }, [dispatch, irrigator.detail])

  return (
    <>
      <UserWrapper pathName='Regante'>
        <DeatilWrapper>
          {loading || !irrigatorDetails ? (
            <>
              <h1>
                <GhostLine />
              </h1>
              <Card className='stat-card'>
                <GhostLine />
                <GhostLine width='60%' />
                <GhostLine width='40%' />
              </Card>
            </>
          ) : (
            <>
              <h1>{irrigatorDetails.name || 'No Disponible'}</h1>
              <Tabs selected={0}>
                <Panel title='información'>
                  <>
                    <Card className='detailCard'>
                      <Detail>
                        <Row>
                          <strong>Código de regante:</strong>
                          <span>
                            {irrigatorDetails.code || 'No Disponible'}
                          </span>
                        </Row>
                        <Row>
                          <strong>Canal:</strong>
                          <span>
                            {`${irrigatorDetails.channel.code}(${irrigatorDetails.channel.name})` ||
                              'No Disponible'}
                          </span>
                        </Row>
                        <Row>
                          <strong>Número de acciones:</strong>
                          <span>{irrigatorDetails.number_of_actions}</span>
                        </Row>
                        <Row>
                          <strong>Estado:</strong>
                          <span
                            style={{
                              color: irrigatorDetails.slow_payer && 'tomato',
                              fontWeight: irrigatorDetails.slow_payer && '900'
                            }}
                          >
                            {irrigatorDetails.slow_payer
                              ? 'Tiene Deuda'
                              : 'Al día'}{' '}
                            <br />
                            {irrigatorDetails.last_payment &&
                              `Último pago: ${irrigatorDetails.last_payment}`}
                          </span>
                        </Row>
                        <Row>
                          <strong>Rol:</strong>
                          <span>{irrigatorDetails.rol || 'No Disponible'}</span>
                        </Row>
                        <Row>
                          <strong>Direción:</strong>
                          <span>
                            {irrigatorDetails.address || 'No Disponible'}
                          </span>
                        </Row>
                        <Row>
                          <strong>Teléfono:</strong>
                          <span>
                            {irrigatorDetails.phone || 'No Disponible'}
                          </span>
                        </Row>
                        <Row>
                          <strong>Geolocalización:</strong>
                          {irrigatorDetails.profile &&
                          irrigatorDetails.profile.coordinates ? (
                            <a
                              href={`https://maps.google.com/maps?q=${irrigatorDetails.profile.coordinates.latitude}%2C${irrigatorDetails.profile.coordinates.longitude}&hl=es`}
                            >{`lat: ${irrigatorDetails.profile.coordinates.latitude} - long: ${irrigatorDetails.profile.coordinates.longitude}`}</a>
                          ) : geoLocation && geoLocation.coordinates ? (
                            <a
                              href={`https://www.google.com/maps/@${geoLocation.coordinates.longitude}%2C${geoLocation.coordinates.latitude}`}
                            >{`lat: ${geoLocation.coordinates.latitude} - long: ${geoLocation.coordinates.longitude}`}</a>
                          ) : (
                            <Button
                              className='geoButton'
                              onClick={() => getLocation()}
                            >
                              <i className='fa fa-map-marker' />
                              obtener coordenadas
                            </Button>
                          )}
                          <br />
                          <span>(punto entrega agua)</span>
                        </Row>
                      </Detail>
                    </Card>
                    <Actions>
                      <Button
                        background='secondary'
                        onClick={() => showModal('information')}
                        className='fa fa-bell'
                        size='20px'
                      ></Button>
                      <Button
                        background='error'
                        onClick={() => showModal('urgent')}
                        className='fa fa-tint-slash'
                        size='20px'
                      ></Button>
                      {['adminacm', 'sectionm'].includes(
                        userLogged.role.type
                      ) ? null : (
                        <Button
                          width='100%'
                          display='block'
                          onClick={() =>
                            history.push({
                              pathname: '/solicitudes/new',
                              state: {
                                name: 'Nuevo reporte de visita',
                                type: 'visitreport',
                                code: irrigatorDetails.code
                              }
                            })
                          }
                        >
                          Agendar Visita
                        </Button>
                      )}
                    </Actions>
                  </>
                </Panel>
                <Panel title='Historial'>
                  {loading || !irrigator.history ? (
                    <Card className='stat-card'>
                      <GhostLine />
                      <GhostLine width='60%' />
                      <GhostLine width='40%' />
                    </Card>
                  ) : (
                    <List
                      items={annotation(irrigator.history)}
                      action={handleItem}
                    />
                  )}
                  <Actions
                    style={{ gridTemplateColumns: '1fr', alignItems: 'center' }}
                  >
                    {'adminacm' === userLogged.role.type ? null : (
                      <Button
                        margin='0 auto'
                        onClick={() =>
                          history.push({
                            pathname: '/solicitudes/new',
                            state: {
                              name: 'Nueva anotación',
                              type: 'annotation',
                              code: irrigatorDetails.code
                            }
                          })
                        }
                      >
                        Crear anotacion
                      </Button>
                    )}
                  </Actions>
                </Panel>
              </Tabs>
            </>
          )}
        </DeatilWrapper>
      </UserWrapper>

      {modal && (
        <Modal
          action={handleModal}
          closeAction={showModal}
          txtAction={modal === 'urgent' ? 'Aviso  de corte' : 'Notificación'}
        >
          <ModalContent>
            <h1>
              {modal === 'urgent'
                ? 'Notificación de corte'
                : 'Enviar notificación'}
            </h1>

            {modal === 'urgent' && (
              <FormInput label='Elegir un Día'>
                <input
                  type='datetime-local'
                  value={form.date}
                  name='date'
                  onChange={e => setForm({ ...form, date: e.target.value })}
                />
              </FormInput>
            )}
            <FormInput label='Mensaje'>
              <input
                onChange={e => setForm({ ...form, message: e.target.value })}
              />
            </FormInput>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

IrrigatorDetail.defaultProps = {
  data: {
    title: 'Deuda primer semestre 2020',
    code: 123,
    channel: 'Vergara',
    dueDate: '12-12-2020',
    payed: true,
    amount: '999.999',
    coordinates: 'no asignadas'
  }
}

export default IrrigatorDetail
