import React, { useState } from 'react'
import UserWrapper from '../../hoc/userWrapper'
import Card from '../../UI/card'
import Button from '../../UI/button'
import Modal from '../../UI/modal'
import { DeatilWrapper, Detail, Row, ModalContent } from './styles'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

const infoTransfer = {
  bank: 'Banco Itaú',
  account: '208905342',
  rut: '82.051.900-0',
  email: 'pagocuota@canalmaule.cl'
}

const DebtDetail = props => {
  const { location } = props
  const history = useHistory()
  const userData = useSelector(({ user }) => user)

  const [modal, showModal] = useState(false)
  const debtData = location.state && location.state.data

  const copyBank = () => {
    const text = `Banco: ${infoTransfer.bank}. Cuenta corriente: ${infoTransfer.account}. RUT: ${infoTransfer.rut}. Correo electronico: ${infoTransfer.email}`
    navigator.clipboard.writeText(text)
    showModal(false)
  }

  return (
    <>
      <UserWrapper
        pathName={`${
          location.state.type === 'payed' ? 'Cuotas Pagadas' : 'Detalle Deuda'
        }`}
      >
        <DeatilWrapper>
          <h1>{debtData.title}</h1>
          <Detail>
            <Row>
              <strong>Código de regante:</strong> <span>{debtData.code}</span>
            </Row>
            <Row>
              <strong>Canal:</strong>{' '}
              <span>{userData.acm && userData.acm.channel}</span>
            </Row>
            <Row>
              <strong>Periodo:</strong>
              <span>{debtData.year}</span>
            </Row>
            <Row>
              <strong>Cuota:</strong>
              <span>{debtData.fees_type}</span>
            </Row>
            <Row>
              <strong>Fecha vencimiento:</strong>
              <span>{debtData.expiration}</span>
            </Row>
            {location.state.type === 'payed' && (
              <h3>{`Monto Cancelado: ${debtData.coin || '$'} ${
                debtData.amount
              }`}</h3>
            )}
            {location.state.type === 'payed' ? (
              <h2>{`Estado: pagado`}</h2>
            ) : (
              <h2>{`Total a pagar: ${debtData.coin} ${debtData.amount}`}</h2>
            )}
          </Detail>

          {location.state.type !== 'payed' && (
            <Card className='info'>
              <i className='fas fa-info-circle' />
              <p>
                Acércate a nuestras oficinas para realizar el pago de tu cuota o
                realiza una transferencia bancaria
              </p>
            </Card>
          )}

          {location.state.type !== 'payed' && (
            <Button
              width='80%'
              display='block'
              onClick={() => showModal(!modal)}
            >
              Datos para transferencia bancaria
            </Button>
          )}

          {location.state.type !== 'payed' && (
            <Button
              width='80%'
              display='block'
              onClick={() =>
                history.push({
                  pathname: '/deudas/new',
                  state: {
                    type: 'requestforattention',
                    debt: true,
                    summary: `Solicito un reporte de deuda del periodo:${debtData.year}, cuota:${debtData.fees_type}, Vencimiento:${debtData.expiration}.`
                  }
                })
              }
            >
              Solicitar Reporte de Deuda
            </Button>
          )}
        </DeatilWrapper>
      </UserWrapper>
      {modal && (
        <Modal action={copyBank} txtAction='copiar'>
          <ModalContent>
            <h1>Datos para transferir</h1>
            <p>
              <strong>Banco: </strong> {infoTransfer.bank}
            </p>
            <p>
              <strong>Cuenta corriente: </strong>
              {infoTransfer.account}
            </p>
            <p>
              <strong>RUT: </strong>
              {infoTransfer.rut}
            </p>
            <p>
              <strong>correo electrónico: </strong>
              {infoTransfer.email}
            </p>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

DebtDetail.defaultProps = {
  debt: {
    title: 'Deuda primer semestre 2020',
    code: 123,
    channel: 'Vergara',
    dueDate: '12-12-2020',
    payed: true,
    amount: '999.999'
  }
}

export default DebtDetail
