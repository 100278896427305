/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import UserWrapper, { ModalContent } from '../../hoc/userWrapper'
import Card from '../../UI/card'
import Button from '../../UI/button'
import FormInput from '../../UI/input'
import { ActionArea, RequestWrapper } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { filterIrrigatorsList } from '../../../store/actions/irrigator'

import { createRequest, getRoles } from '../../../store/actions/bookings'
import { getChannels } from '../../../store/actions/dashboard'
import * as type from '../../../store/reducers/types'
import Modal from '../../UI/modal'
import { useHistory } from 'react-router'
import { checkRole, truncate } from '../../hoc/utils'
import Select from 'react-select'
import moment from 'moment'

const titleForm = {
  visitreport: 'Crear un nuevo reporte de visita',
  channelreport: 'Crear reporte de canal',
  annotation: 'crear anotación',
  requestforattention: 'Crea una nueva solicitud de atención',
  requestforattention_visit: 'Crea una solicitud de visita',
  requestforattention_debt: 'Crea una solicitud de deuta'
}

const subjectRequestVisit = 'Solicitud de visita'

const RequestForm = ({ location }) => {
  const typeForm =
    (location.state && location.state.type) || 'requestforattention'
  const requestVisit = location.state && location.state.visit
  const requestDebt = location.state && location.state.debt
  const summaryRequest = location.state && location.state.summary
  const irrigatorCode = location.state && location.state.code
  const irrigatorsListLimit = 8

  const dispatch = useDispatch()
  const history = useHistory()

  const requests = useSelector(({ requests }) => requests)
  const notification = useSelector(({ notifications }) => notifications)
  const roles = useSelector(({ requests }) => requests.roles)
  const user = useSelector(({ user }) => user)
  const session = useSelector(({ login }) => login)
  const codeActive = useSelector(({ codeActive }) => codeActive)
  const channelsList = useSelector(({ channelsList }) => channelsList)
  const irrigatorsFromDB = useSelector(({ irrigators }) => irrigators)

  const [listRequests, setList] = useState([])
  const [invalid, setInvalid] = useState(true)
  const [irrigatorsList, setIrrigatorsList] = useState([])
  const [irrigatorsListTotal, setIrrigatorsListTotal] = useState('(0 regantes)')
  const [channels, setChannels] = useState([])
  const [form, setForm] = useState({
    type: typeForm || 'requestforattention',
    content: summaryRequest
  })

  const [defaultIrrigator, setDefaultIrrigator] = useState('')

  /* Validar el formulario a enviar */
  const isCompleted = formToSend => {
    let response = false

    const reqOrAnn =
      ['requestforattention', 'annotation'].includes(typeForm) &&
      formToSend.irrigator_code

    const channelReport =
      typeForm === 'channelreport' && formToSend.channel_code

    const visitReport = typeForm === 'visitreport' && formToSend.irrigator_code

    const visitOrChannelReport =
      (visitReport || channelReport) &&
      formToSend.visitreport_data &&
      formToSend.visitreport_data.date

    if (
      (reqOrAnn || visitOrChannelReport) &&
      formToSend.content &&
      formToSend.content.length
    ) {
      response = true
    }

    return response
  }

  const handleForm = (e, formToSend) => {
    e.preventDefault()
    const subject = requestVisit
      ? `${subjectRequestVisit} a ${formToSend.irrigator_code}`
      : truncate(formToSend.content)

    const toSend = {
      ...formToSend,
      subject
    }

    if (isCompleted(toSend)) dispatch(createRequest(toSend))
  }

  const handleModalAction = () => {
    if (typeForm === 'visitreport') {
      dispatch({ type: type.NOTIFICATIONS, notification: false })
      history.push({ pathname: `/visitas` })
    } else {
      dispatch({ type: type.NOTIFICATIONS, notification: false })
      history.push({
        pathname: `/solicitudes/${requests.requestDetail.id}`,
        state: { id: requests.requestDetail.id }
      })
    }
  }

  const totalIrrigators = total => `- ${total} opcion(es)`

  /* Genera una lista que se muestra en el select */
  const irrigatorListFilter = value => {
    const wordToFind = value ? value.toLowerCase() : ''
    if (
      user.assigned_irrigators &&
      ['watchman'].includes(user.association_area.code)
    ) {
      /* Se usan los datos que viene con el celador */
      const list = user.assigned_irrigators
        .map(item => ({
          label: `(${item.code}) ${item.name}`,
          value: item.code
        }))
        .filter(
          item =>
            item.label.toLowerCase().includes(wordToFind) ||
            item.value.includes(wordToFind)
        )

      setIrrigatorsListTotal(totalIrrigators(list.length))
      setIrrigatorsList(list)
    } else {
      dispatch(
        filterIrrigatorsList(0, irrigatorsListLimit, wordToFind, '', true)
      )
    }
  }

  const updateForm = data => {
    let newForm = { ...form, ...data }

    /* El componente recibe un codigo de regante que no debe ser elegido por el usuario */
    if (
      ['visitreport', 'requestforattention', 'annotation'].includes(typeForm) &&
      (irrigatorCode || defaultIrrigator || codeActive)
    )
      newForm = {
        ...newForm,
        irrigator_code: irrigatorCode || defaultIrrigator || codeActive
      }

    setForm(newForm)
  }

  /* Obtiene lista de roles y datos de regante elegido en componente anterior */
  useEffect(() => {
    /* Se obtienen los roles solo para solicitudes de atencion */
    if (typeForm === 'requestforattention') dispatch(getRoles())

    /* Si es un reporte de visita y se recibe un codigo se buscan los datos de ese regante para colocarlo por defecto en el select */
    if (['visitreport', 'annotation'].includes(typeForm) && irrigatorCode)
      dispatch(filterIrrigatorsList(0, 1, irrigatorCode, '', true))
  }, [])

  /* Si es administrador, es un reporte de canal y se tiene channelsList, se setea la lista de canales */
  useEffect(() => {
    if (
      typeForm === 'channelreport' &&
      !['watchman', 'sectionm'].includes(user.association_area?.code)
    ) {
      const options = channelsList.map(channel => ({
        label: channel.name,
        value: channel.code
      }))

      setChannels(options)
    }
  }, [user, channelsList])

  /* Se setea lista de <options> con canales y regantes asignados al usuario sino tiene, se dispara evento para cargar datos desde BD */
  useEffect(() => {
    /* Si es un reporte de canal */
    if (typeForm === 'channelreport') {
      if (user.channels) {
        /* Usuarios que tienen canal asignado */
        const channelList = user.channels.map(channel => ({
          label: channel.name,
          value: channel.code
        }))
        setChannels(channelList)
      } else {
        /* Usuarios que veran todos los canales */
        dispatch(getChannels())
      }
    } else if (user.association_area) {
      /* Solo los usuarios ACM */
      if (
        user.assigned_irrigators &&
        ['watchman'].includes(user.association_area.code)
      ) {
        /* Se usan los datos que viene con el celador */
        const list = user.assigned_irrigators.map(item => ({
          label: `(${item.code}) ${item.name}`,
          value: item.code
        }))

        setIrrigatorsListTotal(totalIrrigators(list.length))
        setIrrigatorsList(list)
      } else {
        /* Se buscan los datos en el servidor */
        dispatch(filterIrrigatorsList(0, irrigatorsListLimit, '', '', true))
      }
    }
  }, [user])

  /* Se setea lista de <options> con roles para solicitudes de atencion */
  useEffect(() => {
    if (roles && typeForm === 'requestforattention') {
      setList(roles)
    }
  }, [roles])

  /* Usar setForm para setear algunos campos */
  useEffect(() => {
    let newForm = { ...form }
    if (['visitreport', 'channelreport'].includes(typeForm))
      newForm = {
        ...newForm,
        visitreport_data: { date: moment().format('YYYY-MM-DDThh:mm') }
      }

    if (roles && typeForm === 'requestforattention')
      roles.forEach(element => {
        const type = requestDebt ? 'administration' : 'watchman'
        if (element.code === type)
          newForm = {
            ...newForm,
            association_area: element.id
          }
      })

    /* El componente recibe un codigo de regante que no debe ser elegido por el usuario */
    if (
      ['visitreport', 'requestforattention', 'annotation'].includes(typeForm) &&
      (irrigatorCode || defaultIrrigator || codeActive)
    )
      newForm = {
        ...newForm,
        irrigator_code: irrigatorCode || defaultIrrigator || codeActive
      }

    setForm(newForm)
  }, [roles, user])

  /* Validacion para activar o no el boton de enviar */
  useEffect(() => {
    /* Desactiva el boton antes de validar que todo esté correcto */
    setInvalid(true)
    if (isCompleted(form)) {
      setInvalid(false)
    }
  }, [form])

  /* Si es un celador lista de regantes */
  useEffect(() => {
    /* En caso de reporte de canal para un codigo especificado */

    if (
      irrigatorsFromDB.count === 1 &&
      ['visitreport', 'annotation'].includes(typeForm)
    ) {
      const irrigator = irrigatorsFromDB.data[0]

      irrigatorCode === irrigator.code &&
        setDefaultIrrigator({
          label: `(${irrigator.code}) ${irrigator.name}`,
          value: irrigator.code
        })
    }

    if (
      session.role &&
      !['irrigator', 'watchman'].includes(session.role.type)
    ) {
      const list = irrigatorsFromDB.data.map(item => ({
        label: `(${item.code}) ${item.name}`,
        value: item.code
      }))

      setIrrigatorsListTotal(totalIrrigators(irrigatorsFromDB.count))
      setIrrigatorsList(list)
    }
  }, [session, irrigatorsFromDB])

  return (
    <UserWrapper pathName={location.state.name || 'Nueva Solicitud'}>
      <RequestWrapper onSubmit={e => handleForm(e, form)}>
        <h1>
          {
            titleForm[
              requestVisit
                ? 'requestforattention_visit'
                : requestDebt
                ? 'requestforattention_debt'
                : typeForm
            ]
          }
        </h1>
        <Card className='form-card'>
          {typeForm === 'channelreport' && (
            <FormInput label='Selecciona un canal' width='100%'>
              <select
                onChange={e => updateForm({ channel_code: e.target.value })}
                value={form.channel_code ? form.channel_code : ''}
              >
                {channels && channels.length ? (
                  <option value='' disabled>
                    Seleccione un canal
                  </option>
                ) : (
                  <option value='' disabled>
                    No tienes canales asignados
                  </option>
                )}
                {channels && channels.length
                  ? channels.map(channel => (
                      <option
                        key={`option-${channel.value}`}
                        value={channel.value}
                      >
                        {channel.label}
                      </option>
                    ))
                  : null}
              </select>
            </FormInput>
          )}
          {/* Si es regante, escoge el area a quien va dirigida su solicitud. No es una solicitud de visita */}
          {typeForm === 'requestforattention' &&
            form.association_area &&
            !requestVisit &&
            !requestDebt && (
              <FormInput
                label='¿A quién está dirigida tu solicitud de atención?'
                width='100%'
              >
                <select
                  onChange={e =>
                    updateForm({ association_area: e.target.value })
                  }
                  value={form.association_area}
                >
                  {listRequests.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </FormInput>
            )}

          {!checkRole(session, 'irrigator') &&
            typeForm !== 'channelreport' &&
            (!irrigatorCode || (irrigatorCode && defaultIrrigator)) && (
              <FormInput
                label={
                  irrigatorCode
                    ? `Regante seleccionado`
                    : `Seleccionar regante ${irrigatorsListTotal}`
                }
              >
                <Select
                  isDisabled={irrigatorCode ? true : false}
                  defaultValue={defaultIrrigator}
                  onInputChange={irrigatorListFilter}
                  isClearable
                  options={irrigatorsList}
                  classNamePrefix='select'
                  placeholder='Seleccione un regante'
                  onChange={e =>
                    updateForm({ irrigator_code: e ? e.value : '' })
                  }
                />
              </FormInput>
            )}

          {!checkRole(session, 'irrigator') &&
            (typeForm === 'channelreport' || typeForm === 'visitreport') &&
            form.visitreport_data && (
              <FormInput label='Fecha de la visita'>
                <input
                  type='datetime-local'
                  value={form.visitreport_data.date}
                  onChange={e =>
                    updateForm({
                      visitreport_data: { date: e.target.value }
                    })
                  }
                />
              </FormInput>
            )}
          <FormInput label='Breve descripción'>
            <textarea
              defaultValue={form.content}
              placeholder='Describa brevemente su solicitud.'
              cols='6'
              rows='6'
              onChange={e => updateForm({ content: e.target.value })}
            ></textarea>
          </FormInput>

          <ActionArea className='actions'>
            <Button className='btn-send' type='submit' disabled={invalid}>
              Enviar
            </Button>
          </ActionArea>
        </Card>
        <p
          style={{
            padding: '1rem 1rem 1rem 1rem',
            textAlign: 'right',
            width: 'calc(100% - 15vw)',
            color: 'f91414'
          }}
        >
          Formulario v0.220207.0
        </p>
      </RequestWrapper>
      {notification && notification.hasOwnProperty('message') && (
        <Modal>
          <ModalContent type='success'>
            <i className='fas fa-check'></i>
            <p>{notification.message}</p>
            <Button
              background='primary'
              width='100%'
              onClick={() => {
                handleModalAction()
              }}
            >
              Volver
            </Button>
          </ModalContent>
        </Modal>
      )}
    </UserWrapper>
  )
}

export default RequestForm
