import React from 'react'
import { WrapperDots } from './styles'

const Dots = () => {
  return (
    <WrapperDots>
      <div className='dot1'></div>
      <div className='dot2'></div>
      <div className='dot3'></div>
    </WrapperDots>
  )
}

export default Dots
