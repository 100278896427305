import axios from '../../plugins/axios'
import moment from 'moment'
import * as type from '../reducers/types'
import { apiUrl, getAuth } from './utils'

export const fetchDashboard = (code, user) => async dispatch => {
  dispatch({ type: type.LOADING, load: true })

  const url = code
    ? `${apiUrl}/dashboard-irrigators/${code}?_sort=createdAt:desc`
    : `${apiUrl}/dashboard-acmuser`

  /* Ultima fecha de actualización de las deudas */
  let last_update_fees = ''
  await axios
    .get(`${apiUrl}/file-update-controls/last-update-fees`, getAuth())
    .then(
      ({ data }) =>
        (last_update_fees = moment(data.createdAt).format('DD/MM/YYYY'))
    )

  /* Parametros adicionales que se les mande al store */
  let aditionalData = { last_update_fees }

  /* Si es un ususario de ACM */
  if (user && user.id) {
    /* Para el caso de los jefes de seccion. Lista de sus celadores */
    if (user.assigned_users && user.assigned_users.length)
      aditionalData = {
        ...aditionalData,
        total_assigned_watchmans: user.assigned_users.length
      }

    /* Fecha de ultima actualizacion de la lista de regantes */
    let last_update_irrigators = ''
    await axios
      .get(`${apiUrl}/file-update-controls/last-update-irrigator`, getAuth())
      .then(
        ({ data }) =>
          (last_update_irrigators = moment(data.createdAt).format('DD/MM/YYYY'))
      )

    let total_irrigators = 0
    await axios
      .get(`${apiUrl}/irrigators/count`, getAuth())
      .then(({ data }) => (total_irrigators = data))

    aditionalData = {
      ...aditionalData,
      total_irrigators,
      last_update_irrigators
    }
  }

  return axios
    .get(url, getAuth())
    .then(({ data }) => {
      dispatch({
        type: type.FETCH_DASHBOARD,
        dash: { ...data, ...aditionalData }
      })
    })
    .catch(err => {
      dispatch({ type: type.ERROR, error: err })
    })
    .finally(() => {
      dispatch({ type: type.LOADING, load: false })
    })
}

// get channels list
export const getChannels = () => async dispatch => {
  const url = `${apiUrl}/channels?_sort=name:asc&_limit=200`

  return axios
    .get(url, getAuth())
    .then(({ data }) => {
      dispatch({
        type: type.GET_CHANNELS,
        channels: data
      })
    })
    .catch(err => dispatch({ type: type.ERROR, error: err }))
}
