import moment from 'moment'
import React, { useState, useRef } from 'react'
import FormInput from '../../UI/input'
import { FormWrapper } from './styles'
import Select from 'react-select'
import Button from '../../UI/button'

const createLists = (arr, type) => {
  let list
  const value = type === 'channels' ? 'code' : 'id'
  if (arr.length > 0) {
    list = arr?.map(item => ({
      value: item[value],
      label: item.name
    }))
  }

  return list
}

const msgType = [
  { value: 'information', label: 'Información' },
  { value: 'urgent', label: 'Urgente' }
]

const prevFilename = name => {
  let newName = name
  /* Si es muy largo se reduce */
  if (name.length > 50) {
    newName = name.substr(0, 30) + ' ... ' + name.substr(-10, 10)
  }

  return newName
}

export const FormIrrigator = props => {
  const { submitAction, channels } = props
  const channelsList = createLists(channels, 'channels')

  const [form, setForm] = useState({
    date: moment().format('YYYY-MM-DD'),
    hour: moment().add(1, 'hours').format('HH:00')
  })

  const handleChange = e => {
    let { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleSelectChange = (list, type) => {
    let values = list?.map(item => item.value)
    setForm({ ...form, [type]: values })
  }

  const handleSubmit = () => {
    const activation = moment(`${form.date} ${form.hour}`, 'YYYY-MM-DD HH:mm')
      .utc()
      .toString()
    submitAction({ ...form, activation })
  }

  /* Manejo de archivos */
  const hiddenFileInput = useRef(null)
  const handleFileClick = () => {
    hiddenFileInput.current.click()
  }
  const handleFileToSend = e => {
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      setForm({ ...form, file })
    }

    reader.readAsDataURL(file)
  }

  return (
    <FormWrapper>
      <FormInput label='Selecciona un canal'>
        <Select
          isMulti
          options={channelsList}
          classNamePrefix='select'
          placeholder='¿A que canal quieres notificar?'
          onChange={e => handleSelectChange(e, 'channels')}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </FormInput>
      <FormInput label='Tipo de mensaje'>
        <Select
          options={msgType}
          classNamePrefix='select'
          placeholder='Selecciona un tipo'
          onChange={e => setForm({ ...form, type: e.value })}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </FormInput>
      <FormInput label='Fecha de activación'>
        <input
          type='date'
          name='activation'
          value={form.date}
          min={moment().format('YYYY-MM-DD')}
          onChange={handleChange}
        />
      </FormInput>
      <FormInput label='Elige una hora para el envío'>
        <input
          type='time'
          name='hour'
          value={form.hour}
          onChange={handleChange}
        />
      </FormInput>
      <FormInput label='Descripción de la solicitud de atención'>
        <textarea name='message' onChange={handleChange} />
      </FormInput>

      <input
        type='file'
        accept='image/*,audio/*'
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        onChange={e => handleFileToSend(e)}
      />
      {form.file ? (
        <div
          className='attach-preview'
          onClick={() => {
            const newForm = { ...form }
            delete newForm.file
            setForm(newForm)
          }}
        >
          Archivos {prevFilename(form.file.name)}
          <i className='fas fa-times' />
        </div>
      ) : (
        <div>
          <Button
            display='block'
            background='rgba(87,162,198,1)'
            onClick={() => handleFileClick()}
            width='100%'
            margin='0 0 16px 0'
          >
            <i className='fas fa-paperclip'></i> Adjuntar archivo
          </Button>
        </div>
      )}

      <Button className='submit' onClick={handleSubmit}>
        Enviar
      </Button>
    </FormWrapper>
  )
}

export const FormWatchman = props => {
  const { submitAction, watchmans, channels } = props
  const watchList = createLists(watchmans)
  const channelsList = createLists(channels, 'channels')

  const [form, setForm] = useState({
    date: moment().format('YYYY-MM-DD'),
    hour: moment().add(1, 'hours').format('HH:00'),
    association_area_code: 'watchman'
  })

  const [selectedOption, setSelectedOption] = useState({
    channel: null,
    watchman: null
  })
  const [showSelectWatchman, setSelectWatchman] = useState(false)

  const handleSubmit = () => {
    const activation = moment(`${form.date} ${form.hour}`, 'YYYY-MM-DD HH:mm')
      .utc()
      .toString()
    submitAction({ ...form, activation })
  }

  const handleChange = e => {
    let { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleSelectChange = (list, type) => {
    type === 'watchman'
      ? setSelectedOption({ watchman: list })
      : setSelectedOption({ channel: list })

    let values = list?.map(item => item.value)
    setForm({ ...form, [type]: values })
  }

  /* Manejo de archivos */
  const hiddenFileInput = useRef(null)
  const handleFileClick = () => {
    hiddenFileInput.current.click()
  }
  const handleFileToSend = e => {
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      setForm({ ...form, file })
    }

    reader.readAsDataURL(file)
  }

  return (
    <FormWrapper>
      <div
        style={{
          textAlign: 'left',
          marginBottom: '8px'
        }}
      >
        <label>
          <input
            type='checkbox'
            id=''
            onChange={() => {
              setSelectedOption({
                channel: null,
                watchman: null
              })
              const newForm = { ...form }
              delete newForm.channel_code
              delete newForm.watchman
              setForm(newForm)
              setSelectWatchman(!showSelectWatchman)
            }}
          />
          <span style={{ marginLeft: '16px' }}>Escoger celador(es)</span>
        </label>
      </div>
      {showSelectWatchman ? (
        <FormInput label='Selecciona los celadores a notificar'>
          <Select
            value={selectedOption.watchman}
            isMulti
            options={watchList}
            classNamePrefix='select'
            placeholder='Todos'
            onChange={e => handleSelectChange(e, 'watchman')}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </FormInput>
      ) : (
        <FormInput label='Selecciona un canal'>
          <Select
            value={selectedOption.channel}
            isMulti
            options={channelsList}
            classNamePrefix='select'
            placeholder='¿A que canal quieres notificar?'
            onChange={e => handleSelectChange(e, 'channel_code')}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </FormInput>
      )}
      <FormInput label='Tipo de mensaje'>
        <Select
          options={msgType}
          classNamePrefix='select'
          placeholder='Selecciona un tipo'
          onChange={e => setForm({ ...form, type: e.value })}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </FormInput>
      <FormInput label='Fecha de activación'>
        <input
          type='date'
          name='date'
          value={form.date}
          min={moment().format('YYYY-MM-DD')}
          onChange={handleChange}
        />
      </FormInput>
      <FormInput label='elige una hora para el envío'>
        <input
          type='time'
          name='hour'
          min={'00:00'}
          step={'10'}
          value={form.hour}
          onChange={handleChange}
        />
      </FormInput>
      <FormInput label='Descripción de la solicitud de atención'>
        <textarea name='message' onChange={handleChange} />
      </FormInput>

      <input
        type='file'
        accept='image/*,audio/*'
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        onChange={e => handleFileToSend(e)}
      />
      {form.file ? (
        <div
          className='attach-preview'
          onClick={() => {
            const newForm = { ...form }
            delete newForm.file
            setForm(newForm)
          }}
        >
          Archivos {prevFilename(form.file.name)}
          <i className='fas fa-times' />
        </div>
      ) : (
        <div>
          <Button
            display='block'
            background='rgba(87,162,198,1)'
            onClick={() => handleFileClick()}
            width='100%'
            margin='0 0 16px 0'
          >
            <i className='fas fa-paperclip'></i> Adjuntar archivo
          </Button>
        </div>
      )}

      <Button className='submit' onClick={handleSubmit}>
        Enviar
      </Button>
    </FormWrapper>
  )
}

export const FormSectionm = props => {
  const { submitAction, sectionms } = props
  const sectionmList = createLists(sectionms)

  const [form, setForm] = useState({
    date: moment().format('YYYY-MM-DD'),
    hour: moment().add(1, 'hours').format('HH:00'),
    association_area_code: 'sectionm'
  })

  const [selectedOption, setSelectedOption] = useState({
    sectionm: null
  })

  const handleSubmit = () => {
    const activation = moment(`${form.date} ${form.hour}`, 'YYYY-MM-DD HH:mm')
      .utc()
      .toString()
    submitAction({ ...form, activation })
  }

  const handleChange = e => {
    let { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleSelectChange = list => {
    setSelectedOption({ sectionm: list })

    let values = list?.map(item => item.value)
    setForm({ ...form, watchman: values })
  }

  /* Manejo de archivos */
  const hiddenFileInput = useRef(null)
  const handleFileClick = () => {
    hiddenFileInput.current.click()
  }
  const handleFileToSend = e => {
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      setForm({ ...form, file })
    }

    reader.readAsDataURL(file)
  }

  return (
    <FormWrapper>
      <FormInput label='Selecciona los Jefes de Sección'>
        <Select
          value={selectedOption.sectionm}
          isMulti
          options={sectionmList}
          classNamePrefix='select'
          placeholder='Todos'
          onChange={e => handleSelectChange(e, 'watchman')}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </FormInput>

      <FormInput label='Tipo de mensaje'>
        <Select
          options={msgType}
          classNamePrefix='select'
          placeholder='Selecciona un tipo'
          onChange={e => setForm({ ...form, type: e.value })}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      </FormInput>
      <FormInput label='Fecha de activación'>
        <input
          type='date'
          name='date'
          value={form.date}
          min={moment().format('YYYY-MM-DD')}
          onChange={handleChange}
        />
      </FormInput>
      <FormInput label='elige una hora para el envío'>
        <input
          type='time'
          name='hour'
          min={'00:00'}
          step={'10'}
          value={form.hour}
          onChange={handleChange}
        />
      </FormInput>
      <FormInput label='Descripción de la solicitud de atención'>
        <textarea name='message' onChange={handleChange} />
      </FormInput>

      <input
        type='file'
        accept='image/*,audio/*'
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        onChange={e => handleFileToSend(e)}
      />
      {form.file ? (
        <div
          className='attach-preview'
          onClick={() => {
            const newForm = { ...form }
            delete newForm.file
            setForm(newForm)
          }}
        >
          Archivos {prevFilename(form.file.name)}
          <i className='fas fa-times' />
        </div>
      ) : (
        <div>
          <Button
            display='block'
            background='rgba(87,162,198,1)'
            onClick={() => handleFileClick()}
            width='100%'
            margin='0 0 16px 0'
          >
            <i className='fas fa-paperclip'></i> Adjuntar archivo
          </Button>
        </div>
      )}

      <Button className='submit' onClick={handleSubmit}>
        Enviar
      </Button>
    </FormWrapper>
  )
}
